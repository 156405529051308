<template>
    <CompModal ref="comp_modal" width="400px">
        <iframe :src="src" frameborder="0" width="100%" height="100%" style="display: block;"></iframe>
    </CompModal>
</template>

<script>
import proxy from "../../../api/proxy"

import CompModal from "../../residentdatabase/components/CompModal.vue"

export default {
    components: {
        CompModal,
    },

    data() {
        return {
            src: null,
        }
    },

    methods: {
        display(id) {
            this.src = `${proxy["/custom"].target}/#/pages/im/group/chat?businessType=1&businessCode=2&groupCode=${id}`
            // 显示页面
            this.$refs.comp_modal.display()
        },
    },
}
</script>
